<script setup>
import {computed, onBeforeMount, onMounted, ref} from "vue";
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import {useRouter} from "vue-router";
import Loader from "../../Components/Loader.vue";
import Comments from "../../Components/Comments.vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import FormSelectUser from "../../Components/FormSelectUser.vue";
import FormSelectSearchUser from "../../Components/FormSelectSearchUser.vue";

const { httpErrorsHandler } = useHttpErrorsHandler();
const $toast = useToast();
const router = useRouter();
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const loading = ref(false)
const ticket = ref(null)
const errors = ref(null)
function getTicket() {
    loading.value = true;
    axios.get("/api/tickets/" + router.currentRoute.value.params.id)
        .then(response => {
            ticket.value = response.data;
            loading.value = false;
            if (ticket.value.data.assigned_to_id === null && ticket.value.data.user_id !== authUser.value.id && (authUser.value.role === 'back_office' || authUser.value.role === 'admin')) {
                assignTicketToUser(authUser.value.id)
            }
            resetNotificationNumber()
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
onBeforeMount(() => {
    getTicket()
})

function updateStatus(status) {
    axios.patch("/api/tickets/" + ticket.value.data.id + '/status', { status: status })
        .then(response => {
            ticket.value.data.status = response.data.data.status;
            ticket.value.data.status_label_fr = response.data.data.status_label_fr;
            ticket.value.data.status_badge_color = response.data.data.status_badge_color;
            $toast.success('Le status du ticket a été mis à jour avec succès.', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function updatePriority(priority) {
    axios.patch("/api/tickets/" + ticket.value.data.id + '/priority', { priority: priority })
        .then(response => {
            ticket.value.data.priority = response.data.data.priority;
            ticket.value.data.priority_label_fr = response.data.data.priority_label_fr;
            ticket.value.data.priority_badge_color = response.data.data.priority_badge_color;
            $toast.success('La priorité du ticket a été mise à jour avec succès.', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const form = {
    assigned_to_id: null,
}

function assignTicketToUser(userId) {
    axios.patch("/api/tickets/" + ticket.value.data.id + '/assigned-to', { assigned_to_id: userId })
        .then(response => {
            ticket.value.data.assigned_to = response.data.data.assigned_to;
            ticket.value.data.assigned_to_id = response.data.data.assigned_to_id;
            $toast.success('Le ticket a été assigné avec succès.', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function assignTicketToUserWithModal() {
    if (form.assigned_to_id === null){
        $toast.info('Vous devez sélectionner un utilisateur pour l\'assigner au ticket.', {
            position: 'top',
            duration: 2000,
            dismissible: true,
            pauseOnHover: true,
        });
        return;
    }
    if (form.assigned_to_id === ticket.value.data.assigned_to_id){
        $toast.info('L\'utilisateur sélectionné est déjà assigné au ticket.', {
            position: 'top',
            duration: 2000,
            dismissible: true,
            pauseOnHover: true,
        });
        return;
    }
    assignTicketToUser(form.assigned_to_id)
    // Close modal
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalAssignUser'))
    modaleDelete.hide()
}

function resetNotificationNumber() {
    axios.patch("/api/tickets/" + router.currentRoute.value.params.id + "/reset-notification-number")
        .then()
        .catch(error => {
            httpErrorsHandler(error);
        });
}
</script>

<template>
    <!--Loader-->
    <div class="card" v-show="loading">
        <div class="card-body">
            <loader/>
        </div>
    </div>
    <div v-if="!loading">
        <div class="row border-top">
            <div class="col-lg-12">
                <div class="card mt-n4 mx-n4 mb-n5">
                    <div :class="'bg-'+ticket.data.priority_badge_color+'-subtle'">
                        <div class="card-body py-4 mb-5 mx-2">
                            <div class="row">
                                <div class="col-md ps-3">
                                    <div class="row align-items-center">
                                        <div class="col-md">
                                            <h4 class="fw-semibold" id="ticket-title">#{{ ticket.data.id }} - {{ ticket.data.title }}</h4>
                                            <div class="hstack gap-3 flex-wrap">
                                                <div class="text-muted"><i class="ri-user-3-line align-bottom me-1"></i><span id="ticket-client">{{ ticket.data.user.data.last_name }} {{ ticket.data.user.data.first_name }}</span></div>
                                                <div class="vr"></div>
                                                <div class="text-muted">Créé le : <span class="fw-medium " id="create-date">{{ ticket.data.created_at_fr }}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-9">
                <div class="card">
                    <div class="card-body p-4">
                        <div class="text-body ticket-container" v-html="ticket.data.description"></div>
                    </div>
                </div>
                <comments
                    commentable-type="App\Models\Ticket"
                    :commentable-id="ticket.data.id"
                    bs-class="col-12"
                />
            </div>
            <div class="col-xxl-3">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5 class="card-title mb-0">Détails du ticket</h5>
                        <div class="avatar-xs">
                            <router-link :to="{ name: 'tickets.edit', params:{ id: ticket.data.id } }" class="avatar-title bg-secondary-subtle text-secondary fs-15 rounded"  v-if="ticket.can.update">
                                <i class="ri-edit-2-fill"></i>
                            </router-link>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive table-card">
                            <table class="table table-borderless align-middle mb-0">
                                <tbody>
                                <tr>
                                    <td class="fw-medium">#ID</td>
                                    <td>#{{ ticket.data.id }}</td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Status:</td>
                                    <td>
                                        <span :class="'badge fs-11 bg-'+ticket.data.status_badge_color+'-subtle  text-'+ticket.data.status_badge_color">{{ ticket.data.status_label_fr }}</span>
                                        <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" id="statusDropdown" data-bs-toggle="dropdown" v-if="ticket.can.update_details"></a>
                                        <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                            <li v-if="ticket.data.status !== 'pending'">
                                                <a class="dropdown-item" href="#" @click.prevent="updateStatus('pending')">
                                                    <span class="badge bg-danger-subtle text-danger fs-11">A traiter</span>
                                                </a>
                                            </li>
                                            <li v-if="ticket.data.status !== 'in_progress'">
                                                <a class="dropdown-item" href="#" @click.prevent="updateStatus('in_progress')">
                                                    <span class="badge bg-secondary-subtle text-secondary fs-11">En cours</span>
                                                </a>
                                            </li>
                                            <li v-if="ticket.data.status !== 'resolved'">
                                                <a class="dropdown-item" href="#" @click.prevent="updateStatus('resolved')">
                                                    <span class="badge bg-success-subtle text-success fs-11">Résolu</span>
                                                </a>
                                            </li>
                                            <li v-if="ticket.data.status !== 'closed'">
                                                <a class="dropdown-item" href="#" @click.prevent="updateStatus('closed')">
                                                    <span class="badge bg-dark-subtle text-dark fs-11">Fermé</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Créé par</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0"><img :src="'/format-image/'+ticket.data.user.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" alt="" class="avatar-xs rounded-circle"></div>
                                            <div class="flex-grow-1 ms-2 name">{{ ticket.data.user.data.last_name }} {{ ticket.data.user.data.first_name }}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Centre</td>
                                    <td>
                                        <div v-if="ticket.data.user.data.centers.length > 0">{{ ticket.data.user.data.centers[0].data.name }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Assigné à</td>
                                    <td>
                                        <div class="d-flex align-items-center" v-if="ticket.data.assigned_to !== null">
                                            <div class="flex-shrink-0"><img :src="'/format-image/'+ticket.data.assigned_to.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" alt="" class="avatar-xs rounded-circle"></div>
                                            <div class="flex-grow-1 ms-2 name">{{ ticket.data.assigned_to.data.last_name }} {{ ticket.data.assigned_to.data.first_name }}
                                                <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" data-bs-toggle="modal" data-bs-target="#modalAssignUser" v-if="ticket.can.update_details"></a>
                                            </div>
                                        </div>
                                        <span v-else>
                                            --
                                           <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" data-bs-toggle="modal" data-bs-target="#modalAssignUser" v-if="ticket.can.update_details"></a>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Priorité</td>
                                    <td>
                                        <span :class="'badge bg-'+ticket.data.priority_badge_color+'-subtle text-'+ticket.data.priority_badge_color+' badge-border fs-11'">{{ ticket.data.priority_label_fr }}</span>
                                        <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" id="statusDropdown" data-bs-toggle="dropdown" v-if="ticket.can.update_details"></a>
                                        <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                            <li v-if="ticket.data.priority !== 0">
                                                <a class="dropdown-item" href="#" @click.prevent="updatePriority(0)">
                                                    <span class="badge bg-info-subtle text-info badge-border fs-11">Normale</span>
                                                </a>
                                            </li>
                                            <li v-if="ticket.data.priority !== 1">
                                                <a class="dropdown-item" href="#" @click.prevent="updatePriority(1)">
                                                    <span class="badge bg-warning-subtle text-warning badge-border fs-11">Moyenne</span>
                                                </a>
                                            </li>
                                            <li v-if="ticket.data.priority !== 2">
                                                <a class="dropdown-item" href="#" @click.prevent="updatePriority(2)">
                                                    <span class="badge bg-danger-subtle text-danger badge-border fs-11">Élevée</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Catégorie</td>
                                    <td>
                                        <span class="fs-11 badge bg-dark-subtle text-body align-middle"><i class="fs-12 mdi mdi-label-variant-outline align-middle"></i> {{ ticket.data.category.data.name }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Visibilité</td>
                                    <td>
                                        {{ ticket.data.is_public ? 'Public' : 'Privé' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Date création</td>
                                    <td id="c-date">{{ ticket.data.created_at_fr }}</td>
                                </tr>
                                <tr>
                                    <td class="fw-medium">Dernières activité</td>
                                    <td>{{ ticket.data.updated_at_fr_human }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="ticket.data.attachments.length > 0">
                    <div class="card-header align-items-center d-flex border-bottom-dashed">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-attachment me-2 align-middle fs-4"></i>Fichiers joints</h4>
                    </div>

                    <div class="card-body">

                        <div class="vstack gap-2">

                            <div class="border rounded border-dashed p-2" v-for="(attachment, index) in ticket.data.attachments" :key="index">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 me-3">
                                        <div class="avatar-sm">
                                            <div class="avatar-title bg-light text-secondary rounded fs-24 shadow">
                                                <!--Icon depending mime type-->
                                                <i class="ri-image-2-line" v-if="attachment.data.mime_type.includes('image')"></i>
                                                <i class="ri-video-line" v-else-if="attachment.data.mime_type.includes('video')"></i>
                                                <i class="ri-folder-music-line" v-else-if="attachment.data.mime_type.includes('audio')"></i>
                                                <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('zip')"></i>
                                                <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('pdf')"></i>
                                                <i class="ri-file-text-line" v-else></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 overflow-hidden">
                                        <h5 class="fs-13 mb-1"><a :href="attachment.data.path" target="_blank" class="text-body text-truncate d-block">{{ attachment.data.name }}</a></h5>
                                        <div>{{ attachment.data.size }}</div>
                                    </div>
                                    <div class="flex-shrink-0 ms-2">
                                        <div class="d-flex gap-1">
                                            <a :href="attachment.data.path" target="_blank" class="btn btn-icon text-muted btn-sm fs-18 shadow-none"><i class="ri-download-2-line"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal assign user -->
        <div id="modalAssignUser" class="modal fade" tabindex="-1" aria-labelledby="modalAssignUserLabel" aria-hidden="true" style="display: none;">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalAssignUserLabel">Affecter un utilisateur</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                    </div>
                    <div class="modal-body">
                        <form-select-search-user
                            :errors="errors"
                            label="Assigné à"
                            name="assigned_to_id"
                            :required="true"
                            :data="form.assigned_to_id"
                            @update:field="form.assigned_to_id = ($event !== null ? $event.id : null)"
                            bs-class="col-md-12"
                            :disabled="false"
                            :taggable="false"
                            :label-hidden="false"
                            :user-pre-selected="null"
                            placeholder="Sélectionnez un utilisateur"
                        />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                        <button type="button" class="btn btn-secondary" @click.prevent="assignTicketToUserWithModal">Valider</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>

</style>
