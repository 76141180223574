<script>
import {useAuthUserStore} from "../Store/AuthUserStore.js";
import {mapActions, mapState} from "pinia";

export default {
    name: "TopBar",
    created() {
        this.fetchAuthUser();
    },
    methods: {
        toggleHamburgerMenu() {
            const windowSize = document.documentElement.clientWidth;
            let layoutType = document.documentElement.getAttribute("data-layout");
            let visibilityType = document.documentElement.getAttribute("data-sidebar-visibility");

            document.documentElement.setAttribute("data-sidebar-visibility", "show");

            if (windowSize > 767){
                document.querySelector(".hamburger-icon").classList.toggle("open");
            }

            //For collapse vertical menu
            if (visibilityType === "show" && (layoutType === "vertical" || layoutType === "semibox")) {
                if (windowSize < 1025 && windowSize > 767) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    document.documentElement.getAttribute("data-sidebar-size") === "sm" ?
                        document.documentElement.setAttribute("data-sidebar-size", "") :
                        document.documentElement.setAttribute("data-sidebar-size", "sm");
                } else if (windowSize > 1025) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    document.documentElement.getAttribute("data-sidebar-size") === "lg" ?
                        document.documentElement.setAttribute("data-sidebar-size", "sm") :
                        document.documentElement.setAttribute("data-sidebar-size", "lg");
                } else if (windowSize <= 767) {
                    document.body.classList.add("vertical-sidebar-enable");
                    document.documentElement.setAttribute("data-sidebar-size", "lg");
                }
            }
        },
        logout() {
            localStorage.clear();
            axios.post('/logout')
                .then(() => location.href = '/login')
        },
        ...mapActions(useAuthUserStore, { fetchAuthUser: "fetchAuthUser" }),
    },
    computed: {
        ...mapState(useAuthUserStore, ['authUser']),
    }
}
</script>

<template>
    <header id="page-topbar">
        <div class="layout-width">
            <div class="navbar-header">
                <div class="d-flex">
                    <!-- LOGO -->
                    <div class="navbar-brand-box horizontal-logo">
                        <a href="/" class="logo logo-dark">
                            <span class="logo-sm">
                                <img src="/velzon/assets/images/logo-sm.png" alt="" height="80">
                            </span>
                            <span class="logo-lg">
                                <img src="/velzon/assets/images/logo-dark.png" alt="" height="32">
                            </span>
                        </a>

                        <a href="#" class="logo logo-light">
                            <span class="logo-sm">
                                <img src="/velzon/assets/images/logo-sm.png" alt="" height="80">
                            </span>
                            <span class="logo-lg">
                                <img src="/velzon/assets/images/logo-light.png" alt="" height="32">
                            </span>
                        </a>
                    </div>

                    <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none" id="topnav-hamburger-icon" @click.stop="toggleHamburgerMenu">
                        <span class="hamburger-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </button>
                </div>

                <div class="d-flex align-items-center">

                    <div class="ms-1 header-item d-none d-sm-flex" v-if="authUser.role === 'admin'">
                        <a href="/horizon" target="_blank" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none">
                            <img src="/images/icons/horizon.svg" class="img-fluid custom-icon-svg"  alt="Icon Laravel Horizon"/>
                        </a>
                    </div>

                    <div class="dropdown ms-sm-3 header-item topbar-user">
                        <button type="button" class="btn shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="d-flex align-items-center">
                                <img class="rounded-circle header-profile-user border" :src="'/format-image/'+authUser.profile_picture+'?w=110&h=110&fm=webp&fit=crop'" alt="Avatar">
                                <span class="text-start ms-xl-2">
                                    <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ authUser.first_name }} {{ authUser.last_name }}</span>
                                    <span class="d-none d-xl-block ms-1 fs-12 user-name-sub-text">{{ authUser.role_label_fr }}</span>
                                </span>
                            </span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <!-- item-->
                            <h6 class="dropdown-header">Bienvenue {{ authUser.first_name }} !</h6>
                            <router-link :to="{ name: 'users.profile' }" class="dropdown-item" href="#"><i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Mon profil</span></router-link>
                            <a class="dropdown-item" href="#" @click.prevent="logout"><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span class="align-middle" data-key="t-logout">Déconnexion</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<style scoped>
.custom-icon-svg {
    width: 19px;
    height: 19px;
}
</style>
