<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {computed, inject, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref} from "vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Loader from "../../Components/Loader.vue";
import {useToast} from "vue-toast-notification";
import FormTextField from "../../Components/FormTextField.vue";
import FormSwitch from "../../Components/FormSwitch.vue";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const brandConfig = inject('brandConfig');
const router = useRouter();
const loading = ref(true)
const errors = ref(null)

const center = ref(null)
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
function getCenter(id) {
    loading.value = true;
    axios.get("/api/centers/" + id)
        .then(response => {
            center.value = response.data;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onBeforeMount(() => {
    getCenter(router.currentRoute.value.params.id)
})

const activeTab = ref('addressesTab')


const idToArchive = ref(null);
const indexToArchive = ref(null);
function showModalArchive(id, index) {
    idToArchive.value = id;
    indexToArchive.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalArchive'))
    modaleDelete.show()
}
function archive() {
    axios.delete('/api/addresses/' + idToArchive.value)
        .then(response => {
            getCenter(router.currentRoute.value.params.id)
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalArchive'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

function showModalEdit(id, index) {
    //foreach to map data address
    Object.entries(center.value.data.addresses[index].data).forEach(([key, value]) => {
        if(formEditAddress.hasOwnProperty(key)){
            formEditAddress[key] = value;
        }
    });
    const modaleEdit = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEdit'))
    modaleEdit.show()
}

const formEditAddress = reactive({
    id: null,
    label: null,
    address_line_1: null,
    address_line_2: null,
    postal_code: null,
    city: null,
    country: null,
    is_default: null,
    is_billing: null,
    is_shipping: null,
})

function updateAddress() {
    axios.put('/api/addresses/' + formEditAddress.id, formEditAddress)
        .then(response => {
            getCenter(router.currentRoute.value.params.id)
            $toast.success('Adresse modifiée', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            const modaleEdit = bootstrap.Modal.getInstance(document.getElementById('modalEdit'))
            modaleEdit.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const formCreateAddress = reactive({
    label: null,
    address_line_1: null,
    address_line_2: null,
    postal_code: null,
    city: null,
    country: null,
    is_default: false,
    is_billing: true,
    is_shipping: true,
    addressable_id: router.currentRoute.value.params.id,
    addressable_type: 'App\\Models\\Center',
})

function createAddress() {
    axios.post('/api/addresses', formCreateAddress)
        .then(response => {
            getCenter(router.currentRoute.value.params.id)
            $toast.success('Adresse créée', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            const modaleEdit = bootstrap.Modal.getInstance(document.getElementById('modalCreate'))
            modaleEdit.hide()

            //reset form
            Object.entries(formCreateAddress).forEach(([key, value]) => {
                formCreateAddress[key] = null;
            });
            formCreateAddress.is_default = false;
            formCreateAddress.is_billing = true;
            formCreateAddress.is_shipping = true;
            formCreateAddress.addressable_id = router.currentRoute.value.params.id;
            formCreateAddress.addressable_type = 'App\\Models\\Center';

        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function setDefaultAddress(id) {
    axios.put('/api/addresses/' + id + '/set-default')
        .then(response => {
            getCenter(router.currentRoute.value.params.id)
            $toast.success('Adresse par défaut modifiée', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const showArchived = ref(false);
function restore(id){
    axios.post('/api/addresses/' + id + '/restore')
        .then(response => {
            getCenter(router.currentRoute.value.params.id)
            $toast.success('Adresse restaurée', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
</script>

<template>
    <div class="row">
        <div class="col-12" v-show="loading">
            <loader />
        </div>

        <div class="col-xxl-3" v-if="!loading">
            <div class="card">
                <div class="card-body p-4">
                    <div class="text-center">
                        <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                            <img :src="brandConfig.logoBlackSmall" class="rounded-circle avatar-xl img-thumbnail shadow" alt="user-profile-image">
                        </div>
                        <h5 class="fs-16 mb-1">{{ center.data.name }}</h5>
                        <p class="text-muted">#{{ center.data.id }}</p>
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item avatar-xs" title="Appeler le centre">
                                <a :href="'tel:'+center.data.phone_number" class="avatar-title bg-success-subtle text-success fs-15 rounded">
                                    <i class="ri-phone-line"></i>
                                </a>
                            </li>
                            <li class="list-inline-item avatar-xs" title="Envoyer un email" v-if="center.data.email !== null">
                                <a :href="'mailto:'+center.data.email" class="avatar-title bg-warning-subtle text-warning fs-15 rounded">
                                    <i class="ri-mail-line"></i>
                                </a>
                            </li>
                            <li class="list-inline-item avatar-xs" title="Modifier" v-if="center.can.update">
                                <router-link :to="{ name: 'centers.edit', params:{ id: center.data.id } }" class="avatar-title bg-secondary-subtle text-secondary fs-15 rounded">
                                    <i class="ri-edit-2-fill"></i>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!--end card-->
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title mb-3">Info</h5>
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                            <tr>
                                <th class="ps-0" scope="row">Téléphone :</th>
                                <td class="text-body">
                                    <a :href="'tel:'+center.data.phone_number" class="text-secondary">{{ center.data.phone_number }}</a>
                                </td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">E-mail :</th>
                                <td class="text-body">
                                    <a :href="'mailto:'+center.data.email" class="text-secondary">{{ center.data.email }}</a>
                                </td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Forme juridique :</th>
                                <td class="text-body">{{ center.data.legal_form }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Dénomination :</th>
                                <td class="text-body">{{ center.data.legal_name }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Numéro de TVA :</th>
                                <td class="text-body">{{ center.data.vat_number }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">SIRET :</th>
                                <td class="text-body">{{ center.data.siret_number }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">SIREN :</th>
                                <td class="text-body">{{ center.data.siren_number }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Capital :</th>
                                <td class="text-body">{{ center.data.registered_capital }} €</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Nom HubSpot</th>
                                <td class="text-body">{{ center.data.hubspot_name }}</td>
                            </tr>
                            <tr>
                                <th class="ps-0" scope="row">Créé le</th>
                                <td class="text-body">{{ center.data.created_at_fr }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xxl-9" v-if="!loading">
            <div class="card">
                <div class="card-header">
                    <ul class="nav nav-tabs nav-tabs-custom card-header-tabs border-bottom-0 nav-secondary text-dark" role="tablist">
                        <li class="nav-item" @click.prevent="activeTab = 'addressesTab'">
                            <a class="nav-link" :class="{ 'active': activeTab === 'addressesTab' }" data-bs-toggle="tab" href="#addressesTab" role="tab">
                                <i class="far fa-user"></i> Adresses
                            </a>
                        </li>
                        <li class="nav-item" @click.prevent="activeTab = 'usersTab'">
                            <a class="nav-link" :class="{ 'active': activeTab === 'usersTab' }" data-bs-toggle="tab" href="#usersTab" role="tab">
                                <i class="fas fa-home"></i> Utilisateurs
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content">
                        <div class="tab-pane" :class="{ 'active': activeTab === 'addressesTab' }" id="addressesTab" role="tabpanel">

                            <div class="d-flex justify-content-end">
                                <!--btn archive-->
                                <a href="#" class="btn btn-dark btn-sm" @click.prevent="showArchived = true" v-if="!showArchived">
                                    <i class="ri-archive-fill me-1 align-bottom"></i> Archives
                                </a>
                                <a href="#" class="btn btn-success btn-sm" @click.prevent="showArchived = false" v-else>
                                    <i class="ri-map-pin-fill me-1 align-bottom"></i> Adresses
                                </a>

                                <!--Btn add address-->
                                <a href="#" class="btn btn-secondary btn-sm ms-2"  data-bs-toggle="modal" data-bs-target="#modalCreate" v-if="authUser.can.create_address">
                                    <i class="ri-add-fill me-1 align-bottom"></i> Ajouter
                                </a>
                            </div>

                            <div class="table-responsive table-card mt-2">
                                <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                    <thead class="table-light">
                                    <tr>
                                        <th class="col-3" scope="col">Libellé</th>
                                        <th class="col-3" scope="col">Adresse</th>
                                        <th class="col-1 text-center" scope="col">Défaut ?</th>
                                        <th class="col-1 text-center" scope="col">Facturation ?</th>
                                        <th class="col-1 text-center" scope="col">Livraison ?</th>
                                        <th class="col-1" scope="col">Création</th>
                                        <th class="col-1 text-center" scope="col">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody class="list form-check-all">
                                    <tr v-for="(address, index) in (showArchived ? center.data.addresses_archived : center.data.addresses)" :key="index">
                                        <td>{{ address.data.label }}</td>
                                        <td>{{ address.data.address_line_1}}<br />
                                            <span v-if="address.data.address_line_2 !== null">{{ address.data.address_line_2 }}<br /></span>
                                            {{ address.data.postal_code + ' ' + address.data.city + ', ' + address.data.country }}
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-success-subtle text-success fs-11" v-if="address.data.is_default">Oui</span>
                                            <span class="badge bg-danger-subtle text-danger fs-11" v-else>Non</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-success-subtle text-success fs-11" v-if="address.data.is_billing">Oui</span>
                                            <span class="badge bg-danger-subtle text-danger fs-11" v-else>Non</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-success-subtle text-success fs-11" v-if="address.data.is_shipping">Oui</span>
                                            <span class="badge bg-danger-subtle text-danger fs-11" v-else>Non</span>
                                        </td>
                                        <td>{{ address.data.created_at_fr }}</td>
                                        <td class="text-center">
                                            <div v-if="!showArchived">
                                                <!-- Set default -->
                                                <a href="#" class="text-warning" title="Définir comme adresse par défaut" @click.prevent="setDefaultAddress(address.data.id)" v-if="!address.data.is_default && address.can.update">
                                                    <i class="ri-star-fill fs-17 px-1"></i>
                                                </a>
                                                <i class="ri-star-fill fs-17 px-1 text-muted" v-else></i>
                                                <!--UPDATE-->
                                                <a href="#" class="text-info" title="Modifier l'adresse" @click.prevent="showModalEdit(address.data.id, index)" v-if="address.can.update">
                                                    <i class="ri-pencil-fill fs-17 px-1"></i>
                                                </a>
                                                <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                                <!--ARCHIVE-->
                                                <a href="#" class="text-danger" title="Archiver l'adresse" @click.prevent="showModalArchive(address.data.id, index)" v-if="address.can.delete">
                                                    <i class="ri-inbox-archive-fill fs-17 px-1"></i>
                                                </a>
                                                <i class="ri-inbox-archive-fill fs-17 px-1 text-muted" v-else></i>
                                            </div>
                                            <div v-else>
                                                <!--RESTORE-->
                                                <a href="#" class="text-success" title="Restaurer l'adresse" @click.prevent="restore(address.data.id)" v-if="address.can.restore">
                                                    <i class="ri-inbox-unarchive-fill fs-17 px-1"></i>
                                                </a>
                                                <i class="ri-inbox-unarchive-fill fs-17 px-1 text-muted" v-else></i>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr class="text-center font-italic text-muted" v-if="(showArchived ? center.data.addresses_archived : center.data.addresses).length === 0 && !loading">
                                        <td colspan="6">Aucune adresse...</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="tab-pane" :class="{ 'active': activeTab === 'usersTab' }" id="usersTab" role="tabpanel">
                            <div class="table-responsive table-card">
                                <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                    <thead class="table-light">
                                    <tr>
                                        <th class="col-1" scope="col">#ID</th>
                                        <th class="col-2" scope="col">Nom</th>
                                        <th class="col-1" scope="col">Role</th>
                                        <th class="col-2" scope="col">Email</th>
                                        <th class="col-2" scope="col">Téléphone</th>
                                        <th class="col-1" scope="col">Date création</th>
                                        <th class="col-1" scope="col">Dernière activité</th>
                                        <th class="col-1 text-center" scope="col">Détails</th>
                                    </tr>
                                    </thead>
                                    <tbody class="list form-check-all">
                                    <tr v-for="(user, index) in center.data.users" :key="index">
                                        <td class="fw-medium text-dark">#{{ user.data.id }}</td>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0"><img :src="'/format-image/'+user.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" alt="" class="avatar-xs rounded-circle"></div>
                                                <div class="flex-grow-1 ms-2 name">{{ user.data.last_name }} {{ user.data.first_name }}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <span :class="'badge fs-11 bg-'+user.data.role_badge_color+'-subtle  text-'+user.data.role_badge_color">{{ user.data.role_label_fr }}</span>
                                        </td>
                                        <td>{{ user.data.email }}</td>
                                        <td>{{ user.data.phone_number }}</td>
                                        <td>{{ user.data.created_at_fr }}</td>
                                        <td>{{ user.data.last_activity_fr }}</td>
                                        <td class="text-center">
                                            <router-link :to="{ name: 'users.show', params:{ id: user.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</router-link>
                                        </td>
                                    </tr>
                                    <tr class="text-center font-italic text-muted" v-if="center.data.users.length === 0 && !loading">
                                        <td colspan="8">Aucun utilisateur...</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

                <!--Model archive-->
                <div class="modal fade zoomIn" id="modalArchive" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="btn-close" id="deleteRecord-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body p-5 text-center">
                                <div class="text-center">
                                    <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                                    <h4 class="fs-semibold mt-4 text-dark">Archiver l'adresse ?</h4>
                                    <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point d'archiver une adresse.</p>
                                    <div class="hstack gap-2 justify-content-center remove">
                                        <button class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" id="deleteRecord-close" data-bs-dismiss="modal">Annuler</button>
                                        <button class="btn btn-danger" @click.prevent="archive">Archiver</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Model Edit-->
                <div id="modalEdit" class="modal fade zoomIn" tabindex="-1" aria-labelledby="modalEditLabel" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalEditLabel">Modifier une adresse</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <form v-on:submit.prevent="updateAddress">
                                <div class="modal-body row">
                                    <form-text-field
                                        name="label"
                                        label="Libellé"
                                        :label-hidden="false"
                                        @update:field="formEditAddress.label = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formEditAddress.label"
                                        bs-class="col-md-12"
                                    />
                                    <form-text-field
                                        name="address_line_1"
                                        label="Adresse"
                                        :label-hidden="false"
                                        @update:field="formEditAddress.address_line_1 = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formEditAddress.address_line_1"
                                        bs-class="col-md-12"
                                    />

                                    <form-text-field
                                        name="address_line_2"
                                        label="Complément d'adresse"
                                        :label-hidden="false"
                                        @update:field="formEditAddress.address_line_2 = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formEditAddress.address_line_2"
                                        bs-class="col-md-12"
                                    />

                                    <form-text-field
                                        name="postal_code"
                                        label="Code postal"
                                        :label-hidden="false"
                                        @update:field="formEditAddress.postal_code = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formEditAddress.postal_code"
                                        bs-class="col-md-4"
                                    />

                                    <form-text-field
                                        name="city"
                                        label="Ville"
                                        :label-hidden="false"
                                        @update:field="formEditAddress.city = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formEditAddress.city"
                                        bs-class="col-md-4"
                                    />

                                    <form-text-field
                                        name="country"
                                        label="Pays"
                                        :label-hidden="false"
                                        @update:field="formEditAddress.country = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formEditAddress.country"
                                        bs-class="col-md-4"
                                    />

                                    <!--<form-switch-->
                                    <!--    name="is_default"-->
                                    <!--    label="Est-ce l'adresse par défaut ?"-->
                                    <!--    :label-hidden="false"-->
                                    <!--    @update:field="formEditAddress.is_default = $event"-->
                                    <!--    :errors="errors"-->
                                    <!--    :data="formEditAddress.is_default"-->
                                    <!--    bs-class="col-md-12"-->
                                    <!--/>-->

                                    <form-switch
                                        name="is_billing"
                                        label="Est-ce une adresse de facturation ?"
                                        :label-hidden="false"
                                        @update:field="formEditAddress.is_billing = $event"
                                        :errors="errors"
                                        :data="formEditAddress.is_billing"
                                        bs-class="col-md-12"
                                    />

                                    <form-switch
                                        name="is_shipping"
                                        label="Est-ce une adresse de livraison ?"
                                        :label-hidden="false"
                                        @update:field="formEditAddress.is_shipping = $event"
                                        :errors="errors"
                                        :data="formEditAddress.is_shipping"
                                        bs-class="col-md-12"
                                    />
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-soft-dark" data-bs-dismiss="modal">Fermer</button>
                                    <button type="submit" class="btn btn-secondary">Valider</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!--Model Add-->
                <div id="modalCreate" class="modal fade zoomIn" tabindex="-1" aria-labelledby="modalCreateLabel" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalCreateLabel">Modifier une adresse</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <form v-on:submit.prevent="createAddress">
                                <div class="modal-body row">
                                    <form-text-field
                                        name="label"
                                        label="Libellé"
                                        :label-hidden="false"
                                        @update:field="formCreateAddress.label = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formCreateAddress.label"
                                        bs-class="col-md-12"
                                    />
                                    <form-text-field
                                        name="address_line_1"
                                        label="Adresse"
                                        :label-hidden="false"
                                        @update:field="formCreateAddress.address_line_1 = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formCreateAddress.address_line_1"
                                        bs-class="col-md-12"
                                    />

                                    <form-text-field
                                        name="address_line_2"
                                        label="Complément d'adresse"
                                        :label-hidden="false"
                                        @update:field="formCreateAddress.address_line_2 = $event"
                                        :errors="errors"
                                        :required="false"
                                        :data="formCreateAddress.address_line_2"
                                        bs-class="col-md-12"
                                    />

                                    <form-text-field
                                        name="postal_code"
                                        label="Code postal"
                                        :label-hidden="false"
                                        @update:field="formCreateAddress.postal_code = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formCreateAddress.postal_code"
                                        bs-class="col-md-4"
                                    />

                                    <form-text-field
                                        name="city"
                                        label="Ville"
                                        :label-hidden="false"
                                        @update:field="formCreateAddress.city = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formCreateAddress.city"
                                        bs-class="col-md-4"
                                    />

                                    <form-text-field
                                        name="country"
                                        label="Pays"
                                        :label-hidden="false"
                                        @update:field="formCreateAddress.country = $event"
                                        :errors="errors"
                                        :required="true"
                                        :data="formCreateAddress.country"
                                        bs-class="col-md-4"
                                    />

                                    <form-switch
                                        name="is_default"
                                        label="Est-ce l'adresse par défaut ?"
                                        :label-hidden="false"
                                        @update:field="formCreateAddress.is_default = $event"
                                        :errors="errors"
                                        :data="formCreateAddress.is_default"
                                        bs-class="col-md-12"
                                    />

                                    <form-switch
                                        name="is_billing"
                                        label="Est-ce une adresse de facturation ?"
                                        :label-hidden="false"
                                        @update:field="formCreateAddress.is_billing = $event"
                                        :errors="errors"
                                        :data="formCreateAddress.is_billing"
                                        bs-class="col-md-12"
                                    />

                                    <form-switch
                                        name="is_shipping"
                                        label="Est-ce une adresse de livraison ?"
                                        :label-hidden="false"
                                        @update:field="formCreateAddress.is_shipping = $event"
                                        :errors="errors"
                                        :data="formCreateAddress.is_shipping"
                                        bs-class="col-md-12"
                                    />
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-soft-dark" data-bs-dismiss="modal">Fermer</button>
                                    <button type="submit" class="btn btn-secondary">Valider</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
