import { computed } from 'vue';
import { useAuthUserStore } from '../Store/AuthUserStore.js';

export function userIsAdmin() {
    const authUserStore = useAuthUserStore();
    const authUser = computed(() => authUserStore.authUser);
    return authUser.value.role === 'admin';
}

export function userIsManager() {
    const authUserStore = useAuthUserStore();
    const authUser = computed(() => authUserStore.authUser);
    return authUser.value.role === 'manager';
}

export function userIsStaff() {
    const authUserStore = useAuthUserStore();
    const authUser = computed(() => authUserStore.authUser);
    return authUser.value.role === 'staff';
}

export function userIsExterne() {
    const authUserStore = useAuthUserStore();
    const authUser = computed(() => authUserStore.authUser);
    return authUser.value.role === 'external';
}

export function userIsBackOffice() {
    const authUserStore = useAuthUserStore();
    const authUser = computed(() => authUserStore.authUser);
    return authUser.value.role === 'back_office';
}
