<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import {useToast} from "vue-toast-notification";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import axios from "axios";
const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');
const sortingField = ref('updated_at');
const sortingFieldsOrder = reactive({
    id: 'asc',
    updated_at: 'asc',
    last_name: 'asc',
    notifications_center: 'asc',
    contact_reason: 'asc',
});
watch(url, (newValue) => {
    localStorage.setItem('clientRequestsOtherListUrl', url.value);
    localStorage.setItem('clientRequestsOtherSortingField', sortingField.value);
    localStorage.setItem('clientRequestsOtherSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('clientRequestsOtherSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('clientRequestsOtherQuery', query.value);
    localStorage.setItem('clientRequestsOtherFiltersApplied', filtersApplied.value.toString());
});
const isInitialized = ref(false);

onBeforeMount(() => {
    loadSettingsFromLocalStorage();
    loadData(url.value);
});

function loadSettingsFromLocalStorage() {
    const hasStoredSettings = localStorage.getItem('clientRequestsOtherListUrl') != null;
    if (hasStoredSettings) {
        loadStoredSettings();
    } else {
        setDefaultSettings();
    }
}

function loadStoredSettings() {
    url.value = localStorage.getItem('clientRequestsOtherListUrl');
    sortingField.value = localStorage.getItem('clientRequestsOtherSortingField');
    sortingFieldsOrder[sortingField.value] = localStorage.getItem('clientRequestsOtherSortingFieldsOrder');
    searchLoaded.value = localStorage.getItem('clientRequestsOtherSearchLoaded') === 'true';
    query.value = localStorage.getItem('clientRequestsOtherQuery') ?? '';
    filtersApplied.value = localStorage.getItem('clientRequestsOtherFiltersApplied') === 'true';

    const savedFilters = localStorage.getItem('clientRequestsOtherFilters');
    if (savedFilters) {
        Object.assign(filters, JSON.parse(savedFilters));
    }
}

function setDefaultSettings() {
    url.value = '/api/client-requests?only_others=true&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
}

onMounted(() => {
    isInitialized.value = true;
})

//Get data
const loading = ref(true);
const clientRequestsOther = ref([]);
function loadData(urlToFetch) {
    clientRequestsOther.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if (searchLoaded.value) {
                return performSearch(urlToFetch);
            }
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const searchLoaded = ref(false);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response, mustSave = true) {
    clientRequestsOther.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
}

//Ordering table
function changeOrdering(sortingValue) {
    sortingField.value = sortingValue;
    sortingFieldsOrder[sortingField.value] = sortingFieldsOrder[sortingField.value] === 'asc' ? 'desc' : 'asc';

    url.value = searchLoaded.value === true
        ? '/api/search/client-requests?only_others=true&query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        : '/api/client-requests?only_others=true&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    loadData(url.value);
}
function activeSorting(sortingValue){
    return sortingField.value === sortingValue ? 'text-secondary' : 'text-dark';
}

//Searching
const query = ref('');
const search = _.debounce(async () => {
    if (query.value.length > 2) {
        await performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('clientRequestsOtherQuery', query.value);
}, 250);
const performSearch = async (urlPerformSearch = null) => {
    clientRequestsOther.value = [];
    loading.value = true;
    let searchUrl = '/api/search/client-requests?only_others=true&query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }
    let newUrl = new URL(searchUrl, window.location.origin);
    // Update filters
    filters.center_id
        ? newUrl.searchParams.set('center_id', filters.center_id)
        : newUrl.searchParams.delete('center_id');

    filters.status
        ? newUrl.searchParams.set('status', filters.status)
        : newUrl.searchParams.delete('status');

    filters.tag
        ? newUrl.searchParams.set('tag', filters.tag)
        : newUrl.searchParams.delete('tag');

    axios.get(newUrl.href)
        .then(response => {
            paginationAndDataMapping(response.data, false);
            url.value = searchUrl
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
};
const clearSearch = () => {
    clientRequestsOther.value = [];
    let newUrl = '/api/client-requests?only_others=true&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    if (filters.center_id) {
        newUrl += '&center_id=' + filters.center_id;
    }
    if (filters.status) {
        newUrl += '&status=' + filters.status;
    }

    if (filters.tag) {
        newUrl += '&tag=' + filters.tag;
    }

    loadData(newUrl);
    searchLoaded.value = false;
};

const modaleDeleteShown = ref(false);
const idToDelete = ref(null);
const indexToDelete = ref(null);
function showModalDelete(id, index) {
    idToDelete.value = id;
    indexToDelete.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDelete'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function deleteClientRequest() {
    axios.delete('/api/client-requests/' + idToDelete.value)
        .then(response => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    if (modaleDeleteShown.value) {
        const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
        modaleDelete.hide()
    }
})

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const errors = ref(null);
const filters = reactive( {
    center_id: null,
    status: null,
    tag: null,
})
const filtersApplied = ref(true);

function applyFilter(){

    let newUrl = new URL(url.value, window.location.origin);

    filters.center_id
        ? newUrl.searchParams.set('center_id', filters.center_id)
        : newUrl.searchParams.delete('center_id');

    filters.status
        ? newUrl.searchParams.set('status', filters.status)
        : newUrl.searchParams.delete('status');

    filters.tag
        ? newUrl.searchParams.set('tag', filters.tag)
        : newUrl.searchParams.delete('tag');

    filtersApplied.value = true;

    localStorage.setItem('clientRequestsOtherFilters', JSON.stringify(filters));
    localStorage.setItem('clientRequestsOtherFiltersApplied', filtersApplied.value.toString());

    loadData(newUrl.href);
}

watch(
    () => [filters.center_id, filters.status, filters.tag],
    (newValues, oldValues) => {
        if (isInitialized.value) {
            filtersApplied.value = false;
        }
    },
    { deep: true }
);

const centers = ref([]);
onBeforeMount(() => {
    getCenters();
})
function getCenters(){
    centers.value.push({
        text: 'Tous les centres',
        value: null
    })
    axios.get('/api/centers?sortingField=name&sortingOrder=asc&all==true')
        .then(response => {
            response.data.data.forEach(center => {
                centers.value.push({
                    text: center.data.name,
                    value: center.data.id
                })
            })
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const clientRequestStatuses = reactive([
    { value: 'pending', label: 'À traiter', badgeColor: 'danger' },
    { value: 'responded', label: 'Répondu', badgeColor: 'secondary' },
    { value: 'awaiting_information', label: 'Attente d\'information', badgeColor: 'info' },
    { value: 'appointment_scheduled', label: 'RDV programmé', badgeColor: 'success' },
    { value: 'appointment_ongoing', label: 'RDV en cours', badgeColor: 'success' },
    { value: 'won', label: 'Gagné', badgeColor: 'success' },
    { value: 'no_show', label: 'Client non présenté', badgeColor: 'dark' },
    { value: 'lost', label: 'Perdu', badgeColor: 'dark' },
    { value: 'closed', label: 'Fermé', badgeColor: 'dark' },
]);
function changeStatus(status, index, id) {
    if (status === clientRequestsOther.value[index].data.status) {
        return;
    }
    axios.patch("/api/client-requests/" + id + "/status", { status: status })
        .then(() => {
            clientRequestsOther.value[index].data.status = status;
            clientRequestsOther.value[index].data.status_label_fr = clientRequestStatuses.find(s => s.value === status).label;
            clientRequestsOther.value[index].data.status_badge_color = clientRequestStatuses.find(s => s.value === status).badgeColor;
            if (status === 'closed'){
                clientRequestsOther.value[index].data.notifications_center = 0;
            }
            $toast.success('Statut mis à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header border border-dashed border-top-0 border-start-0 border-end-0">
                    <div class="d-flex align-items-center">
                        <h5 class="card-title mb-0 flex-grow-1">Toutes les autres demandes</h5>
                        <router-link :to="{ name:'client-requests.create' }" class="btn btn-secondary" v-if="authUser.can && authUser.can.create_client_request">
                            <i class="ri-add-fill me-1 align-bottom hide-on-mobile"></i> Ajouter
                        </router-link>
                    </div>
                </div>
                <!--Filters-->
                <div  class="card-body">
                    <div class="row g-3">
                        <div class="col-xxl-3">
                            <div class="search-box">
                                <input type="text" class="form-control search" placeholder="Rechercher..."  v-model="query" @input="search" id="searchTickets">
                                <i class="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        <form-select-field
                            name="filters.status"
                            label="Sexe"
                            :label-hidden="true"
                            @update:field="filters.status = $event"
                            :errors="errors"
                            :required="true"
                            :data="filters.status"
                            bs-class="col-xxl-3 col-sm-6"
                            :disabled="false"
                            additional-class="bg-light border-light"
                            placeholder="Tous les statuts"
                            :liste-options="[
                                { text: 'Statuts ouverts (A traiter, Attente d\'information...)', value: 'all_open' },
                                { text: 'Statuts fermés (Fermé, Client non présenté...)', value: 'all_closed' },
                                { text: 'Tous les statuts', value: null },
                                { text: '---', value: null },
                                { text: 'À traiter', value: 'pending' },
                                { text: 'Attente d\'information', value: 'awaiting_information' },
                                { text: 'Répondu', value: 'responded' },
                                { text: 'RDV programmé', value: 'appointment_scheduled' },
                                { text: 'RDV en cours', value: 'appointment_ongoing' },
                                { text: 'Gagné', value: 'won' },
                                { text: 'Client non présenté', value: 'no_show' },
                                { text: 'Perdu', value: 'lost' },
                                { text: 'Fermé', value: 'closed' }
                            ]"
                            margin="mb-0"
                        />

                        <form-select-field
                            name="filters.tag"
                            label="Sexe"
                            :label-hidden="true"
                            @update:field="filters.tag = $event"
                            :errors="errors"
                            :required="true"
                            :data="filters.tag"
                            bs-class="col-xxl-3 col-sm-6"
                            :disabled="false"
                            additional-class="bg-light border-light"
                            placeholder="Tous les tags"
                            :liste-options="[
                                { text: 'Tous les tags', value: null },
                                { text: '---', value: null },
                                { text: 'Promotion', value: 'Promotion' },
                                { text: 'ECU en dev', value: 'ECU en dev' },
                                { text: 'SAV', value: 'SAV' },
                                { text: 'Remise Prépa', value: 'Remise Prépa' },
                                { text: 'Remise origine', value: 'Remise origine' },
                                { text: 'Franchise', value: 'Franchise' },
                                { text: 'Jobs', value: 'Jobs' },
                                { text: 'Jeux-concours', value: 'Jeux-concours' },
                                { text: 'Doublon', value: 'Doublon' },
                            ]"
                            margin="mb-0"
                        />

                        <form-select-field
                            name="filters.center_id"
                            label="Sexe"
                            :label-hidden="true"
                            @update:field="filters.center_id = $event"
                            :errors="errors"
                            :required="true"
                            :data="filters.center_id"
                            bs-class="col-xxl-2 col-sm-10"
                            :disabled="false"
                            additional-class="bg-light border-light"
                            placeholder="Tous les centres"
                            :liste-options="centers"
                            margin="mb-0"
                        />

                        <div class="col-xxl-1 col-sm-2">
                            <button type="button" class="btn w-100" @click.prevent="applyFilter" :class="filtersApplied ? 'btn-dark' : 'btn-warning'">
                                <i class="ri-equalizer-fill me-1 align-bottom hide-on-mobile"></i>
                                Filtrer
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                    <tr>
                                        <th class="col-1 sort">
                                            <a href="#" @click.prevent="changeOrdering('id')" :class="activeSorting('id')" class="fw-bold pe-4">#ID</a>
                                        </th>
                                        <th class="col-1" scope="col">Nom client</th>
                                        <th class="col-1 sort">
                                            <a href="#" @click.prevent="changeOrdering('contact_reason')" :class="activeSorting('contact_reason')" class="pe-4">Raison contact</a>
                                        </th>
                                        <th class="col-2" scope="col">Véhicule</th>
                                        <th class="col-1" scope="col">Prestation</th>
                                        <th class="col-1" scope="col">Centre</th>
                                        <th class="col-1" scope="col">Statut</th>
                                        <th class="col-1 sort">
                                            <a href="#" @click.prevent="changeOrdering('updated_at')" :class="activeSorting('updated_at')" class="pe-4">Dernière MAJ</a>
                                        </th>
                                        <th class="col-1 sort text-center">
                                            <a href="#" @click.prevent="changeOrdering('notifications_center')" :class="activeSorting('notifications_center')" class="pe-4">Notifications</a>
                                        </th>
                                        <th class="col-1 text-center" scope="col">Détails</th>
                                        <th class="col-1 text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(clientRequest, index) in clientRequestsOther" :key="index">
                                    <td class="fw-medium text-dark">#{{ clientRequest.data.id }}</td>
                                    <td>{{ clientRequest.data.client.data.last_name }} {{ clientRequest.data.client.data.first_name }}</td>
                                    <td>{{ clientRequest.data.contact_reason }}</td>
                                    <td>{{ clientRequest.data.car_brand }} - {{ clientRequest.data.car_model }}</td>
                                    <td>{{ clientRequest.data.car_stage }}</td>
                                    <td>{{ clientRequest.data.center.data.name }}</td>
                                    <td>
                                        <span :class="'badge fs-11 bg-'+clientRequest.data.status_badge_color+'-subtle  text-'+clientRequest.data.status_badge_color">{{ clientRequest.data.status_label_fr }}</span>
                                        <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" id="statusDropdown" data-bs-toggle="dropdown" v-if="clientRequest.can.update"></a>
                                        <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                            <li v-for="(status, key) in clientRequestStatuses" :key="key">
                                                <a class="dropdown-item" href="#" @click.prevent="changeStatus(status.value, index, clientRequest.data.id)">
                                                    <span :class="`fs-11 badge bg-${status.badgeColor}-subtle text-${status.badgeColor}`">{{ status.label }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>{{ clientRequest.data.updated_at_fr }}</td>
                                    <td class="text-center">
                                        <span v-if="clientRequest.data.notifications_center > 0" class="badge fs-11 rounded-pill bg-danger-subtle text-danger">{{ clientRequest.data.notifications_center }}</span>
                                    </td>
                                    <td class="text-center">
                                        <router-link :to="{ name: 'client-request-others.show', params:{ id: clientRequest.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</router-link>
                                    </td>
                                    <td class="text-center">
                                        <!--UPDATE-->
                                        <router-link :to="{ name: 'client-requests.edit', params:{ id: clientRequest.data.id } }" class="text-info" title="Modifier la demande" v-if="clientRequest.can.update">
                                            <i class="ri-pencil-fill fs-17 px-1"></i>
                                        </router-link>
                                        <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                        <!--Delete-->
                                        <a href="#" class="text-danger" title="Supprimer la demande" @click.prevent="showModalDelete(clientRequest.data.id, index)" v-if="clientRequest.can.delete">
                                            <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-delete-bin-3-fill fs-17 px-1 text-muted" v-else></i>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="clientRequestsOther.length === 0 && !loading">
                                    <td colspan="11" v-if="searchLoaded">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                        <p class="mt-2 h5">Aucun résultat</p>
                                        <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                    </td>
                                    <td colspan="11" v-else>Aucune demande client...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="11">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                        ></pagination>
                    </div>

                    <modal id="modalDelete" title="Supprimer la demande client ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteClientRequest">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer une demande client.</p>
                        </template>
                    </modal>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
