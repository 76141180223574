<template>
    <div class="mb-3" :class="bsClass">
        <label :for="name" class="form-label" v-if="!labelHidden">
            {{ label }}
            <span class="text-danger" v-if="required"> *</span>
        </label>
        <multiselect
            v-model="optionsSelected"
            :options="listeOptions"
            :multiple="true"
            :close-on-select="false"
            :preserve-search="true"
            :placeholder="placeholder"
            label="name"
            track-by="id"
            :preselect-first="false"
            :hide-selected="false"
            select-label=""
            selected-label="Sélectionné"
            deselect-label=""
        >
        </multiselect>
        <span class="error-message" v-html="errorMessage()"></span>
        <span class="form-text text-muted" v-if="informationContent !== null">
            {{ informationContent }}
        </span>
    </div>
</template>

<script>
import _ from "lodash";
export default {
    name: "FormMultiSelect",
    props: {
        name : {
            type: String,
            required: true
        },
        label : {
            type: String,
            required: true
        },
        labelHidden : {
            type: Boolean,
            required: false,
            default: false
        },
        required : {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        informationContent : {
            type: String,
            required: false,
            default: null
        },
        reference : {
            type: String,
            required: false,
            default: 'multiple-select'
        },
        optionsPreSelected: {
            type: Array,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        },
        listeOptions: {
            type: Array,
            required: true,
            default: []
        },
    },
    data() {
        return {
            optionsSelected : [],
            loading: false,
        }
    },
    methods: {
        clearAll () {
            this.optionsSelected = []
        },
        clearErrors: function() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject: function() {
            return {
                "is-invalid": this.hasError
            }
        },
        errorMessage: function() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        }
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            );
        }
    },
    watch: {
        optionsSelected: function(){
            if( this.optionsSelected === null) {
                return this.$emit("update:field", null);
            } else {
                this.clearErrors(this.name);
                return this.$emit("update:field", this.optionsSelected);
            }
        },
        optionsPreSelected: {
            immediate: true,
            handler(newVal) {
                if (this.optionsPreSelected !== null && this.optionsPreSelected.length > 0) {
                    this.optionsSelected = this.listeOptions.filter(option => newVal.includes(option.id));
                    this.$emit("update:field", this.optionsSelected);
                }
            }
        }
    }
}
</script>

<style>

</style>
