<template>
    <div class="mb-3" :class="bsClass">
        <label :for="name" class="form-label" v-if="!labelHidden">
            {{ label }}
            <span class="text-danger" v-if="required"> *</span>
        </label>
        <multiselect
            :loading="loading"
            v-model="countrySelected"
            :options="countries"
            :multiple="false"
            :close-on-select="true"
            :preserve-search="true"
            :placeholder="placeholder"
            label="name"
            track-by="code"
            :preselect-first="true"
            :hide-selected="false"
            select-label=""
            selected-label="Sélectionné"
            deselect-label=""
            :disabled="disabled"
            class="custom-multiselect"
        >
            <template v-slot:noResult>
                <span class="text-muted">Aucun résultats</span>
            </template>
            <template v-slot:noOptions>
                <span class="text-muted">Écrivez pour rechercher un pays...</span>
            </template>
        </multiselect>
        <span class="error-message" v-html="errorMessage()"></span>
        <span class="form-text text-muted" v-if="informationContent !== null">
            {{ informationContent }}
        </span>
    </div>
</template>


<script>
import axios from 'axios';
export default {
    name: "FormSelectCountry",
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        labelHidden: {
            type: Boolean,
            required: false,
            default: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        informationContent: {
            type: String,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        countryPreSelected: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            countrySelected: null,
            countries: [],
            loading: true
        }
    },
    beforeMount() {
        this.getCountries();
    },
    methods: {
        getCountries() {
            axios.get("/api/countries")
                .then(response => {
                    this.countries = response.data;
                    if (this.countryPreSelected !== null) {
                        let country = this.countries.find(
                            country => country.code === this.countryPreSelected
                        );
                        if (country) {
                            this.countrySelected = country;
                        }
                    }
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearErrors() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject() {
            return {
                "is-invalid": this.hasError
            }
        },
        errorMessage() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        }
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            );
        }
    },
    watch: {
        countrySelected(newVal) {
            if (newVal === null) {
                this.$emit("update:field", null);
            } else {
                this.clearErrors();
                this.$emit("update:field", newVal.code);
            }
        }
    }
}
</script>
