<script setup>

import {useToast} from "vue-toast-notification";
import {computed, inject, nextTick, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import {useHttpErrorsHandler} from "../Composables/httpErrorsHandler.js";
import {useAuthUserStore} from "../Store/AuthUserStore.js";
import FormInputFilePond from "./FormInputFilePond.vue";
import 'viewerjs/dist/viewer.css'
import { api as viewerApi } from "v-viewer"
import Editor from "@tinymce/tinymce-vue";

const props = defineProps({
    messageableType: {
        type: String,
        required: true
    },
    messageableId: {
        type: Number,
        required: true
    },
    maxHeight: {
        type: Number,
        default: 560
    },
    senderType: {
        type: String,
        default: 'user'
    },
    showIsInternal: {
        type: Boolean,
        default: false
    },
    showDefaultMessages: {
        type: Boolean,
        default: false
    },
    defaultMessageCenterId: {
        type: Number,
        default: null,
        required: false
    },
    canAnswer: {
        type: Boolean,
        default: true
    }
})


const $toast = useToast();
const messages = ref([]);
const loading = ref(true)
const errors = ref(null)
const page = ref(1);
const perPage = 30;
const totalMessages = ref(0);
const { httpErrorsHandler } = useHttpErrorsHandler();
const brandConfig = inject('brandConfig');

function getMessages(haveToScrollToBottom = true) {
    loading.value = true;
    axios.get("/api/messages", {
            params: {
                messageable_type: props.messageableType,
                messageable_id: props.messageableId,
                page: page.value,
                per_page: perPage
            }
        })
        .then(response => {
            totalMessages.value = response.data.meta.total;
            messages.value = [...response.data.data, ...messages.value];
            loading.value = false;
            if (haveToScrollToBottom) {
                scrollToBottom(false);
            }
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}

onBeforeMount(() => {
    getMessages()
})

function loadMoreMessages() {
    page.value++;
    getMessages(false);
}

const lastPageIsLoaded = computed(() => {
    return messages.value.length >= totalMessages.value;
});


const message = ref(null);
const newMessageId = ref(null);
const formValid = ref(false);
const form = reactive({
    body: '',
    messageable_type: props.messageableType,
    messageable_id: props.messageableId,
    sender_type: props.senderType,
    attachments: [],
    is_internal: false

});
watch(form, () => {
    formValid.value = form.attachments.length > 0 || form.body.length > 0;
});
const sendingMessage = ref(false);
function addMessage() {
    sendingMessage.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (key === 'attachments') {
            return Array.from(value).forEach(attachment => {
                formData.append(`${key}[]`, attachment);
            });
        }
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    axios.post("/api/messages", formData)
        .then(response => {
            const newMessage = response.data;
            newMessageId.value = newMessage.data.id;
            messages.value.push(newMessage);
            totalMessages.value++;
            form.body = '';
            form.attachments = [];
            showFileInput.value = false;
            scrollToBottom(true);
            nextTick(() => {
                autoResize();
            });
            sendingMessage.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            sendingMessage.value = false;
        });
}

const messagesContainer = ref(null);
function scrollToBottom(smooth = false) {
    nextTick(() => {
        const scrollContainer = messagesContainer.value ? messagesContainer.value.querySelector('.simplebar-content-wrapper') : null;
        if (scrollContainer) {
            if (smooth) {
                scrollContainer.scrollTo({
                    top: scrollContainer.scrollHeight,
                    behavior: 'smooth'
                });
            } else {
                scrollContainer.scrollTop = scrollContainer.scrollHeight;
            }
        }
    });
}
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const groupedMessages = computed(() => {
    let grouped = [];
    let currentGroup = [];

    messages.value.forEach((msg, index) => {
        if (index === 0 || messages.value[index - 1].data.sender_id !== msg.data.sender_id) {
            if (currentGroup.length) {
                grouped.push(currentGroup);
            }
            currentGroup = [msg];
        }
        else {
            currentGroup.push(msg);
        }
    });

    if (currentGroup.length) {
        grouped.push(currentGroup);
    }

    return grouped;
});

const isCurrentUser = (message) => {
    return message.data.sender_id === authUser.value.id;
};

const getMessageClass = (message) => {
    return {
        'chat-list': true,
        'left': !isCurrentUser(message),
        'right': isCurrentUser(message)
    };
};

const isLastMessageRead = (messageGroup) => {
    const lastMessage = messageGroup[messageGroup.length - 1];
    return lastMessage.data.read_at_fr !== '--';
};

const textareaRef = ref(null);

const autoResize = () => {
    if (textareaRef.value) {
        textareaRef.value.style.height = 'auto';
        textareaRef.value.style.height = textareaRef.value.scrollHeight + 'px';
    }
};

watch(message, () => {
    autoResize();
}, { immediate: true });

const showFileInput = ref(false);

watch(showFileInput, () => {
    if (!showFileInput.value){
        form.attachments = [];
    }
});


const previewImage = (imgPath) => {
    event.preventDefault()
    viewerApi({
        images: [imgPath],
        options: {
            toolbar: false,
            transition: false,
            loading: true,
        }
    })
}

function markMessagesAsRead() {
    axios.post("/api/messages/mark-as-read", {
            messageable_type: props.messageableType,
            messageable_id: props.messageableId
        })
        .then(response => {})
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onMounted(() => {
    markMessagesAsRead();
});

function deleteComment(commentId) {
    axios.delete("/api/messages/" + commentId)
        .then(response => {
            messages.value = messages.value.filter(message => message.data.id !== commentId);
            totalMessages.value--;
            $toast.success('Message supprimé', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function deleteAttachment(attachmentId) {
    axios.delete("/api/attachments/" + attachmentId)
        .then(response => {
            messages.value.forEach(message => {
                if (message.data.attachments) {
                    message.data.attachments = message.data.attachments.filter(attachment => attachment.data.id !== attachmentId);
                }

                if (message.data.attachments.length === 0 && message.data.body === null) {
                    messages.value = messages.value.filter(msg => msg.data.id !== message.data.id);
                    totalMessages.value--;
                }
            });
            $toast.success('Fichier supprimé', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onMounted(() => {
    if (props.messageableType === 'App\\Models\\ClientRequest') {
        Echo.channel(`client_requests.${props.messageableId}`)
            .listen('MessageSentEvent', (e) => {
                if (e.message.data.sender_id !== authUser.value.id) {
                    messages.value.push(e.message);
                    scrollToBottom(true);
                    markMessagesAsRead();
                }
            });
    }
    if (props.messageableType === 'App\\Models\\InformationRequest') {
        Echo.channel(`information_requests.${props.messageableId}`)
            .listen('MessageSentEvent', (e) => {
                if (e.message.data.sender_id !== authUser.value.id) {
                    messages.value.push(e.message);
                    scrollToBottom(true);
                    markMessagesAsRead();
                }
            });
    }
});

onBeforeUnmount(() => {
    if (props.messageableType === 'App\\Models\\ClientRequest') {
        Echo.leave(`client_requests.${props.messageableId}`);
    }
    if (props.messageableType === 'App\\Models\\InformationRequest') {
        Echo.leave(`information_requests.${props.messageableId}`);
    }
});

const defaultMessages = ref([]);
const defaultMessagesLoaded = ref(false);
function getDefaultMessages() {
    if (props.showDefaultMessages && !defaultMessagesLoaded.value){
        axios.get("/api/default-messages", {
            params: {
                sortingField: 'title',
                sortingOrder: 'asc',
                all: 1,
                center_id: props.defaultMessageCenterId
            }
        })
        .then(response => {
            defaultMessages.value = response.data.data;
            defaultMessagesLoaded.value = true;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
    }

}

function setDefaultMessage(message) {
    form.body = form.body + message;
    nextTick(() => {
        autoResize();
    });
}

const tinyMceLoading = ref(true);
const tinyMceConfig = {
    license_key: 'gpl',
    base_url: '/tinymce/js/tinymce',
    suffix: '.min',
    contextmenu: false,
    language: 'fr_FR',
    menubar: false,
    content_style: 'body { font-family: \'Inter\', sans-serif; font-size: 13px; }',
    statusbar: false,
    plugins: ['lists', 'link', 'autoresize', 'emoticons', 'fullscreen'],
    toolbar: 'bold italic underline | alignleft aligncenter | bullist numlist | link | forecolor | emoticons fullscreen',
    paste_data_images: false,
    paste_as_text: false,
    autoresize_bottom_margin: 0,
    paste_webkit_styles: 'color font-weight strong',
    min_height: 100,
    max_height: 400,
    browser_spellcheck: true,
    init_instance_callback: function(editor) {
        tinyMceLoading.value = false;
    },
    image_class_list: [
        {title: 'Image responsive', value: 'img-fluid img-thumbnail'},
    ],
    paste_postprocess: function(plugin, args) {
        args.node.innerHTML = args.node.innerHTML.replace(/style="color: #2d65cd;"/g, '');
        args.node.innerHTML = args.node.innerHTML.replace(/style="color: #212529;"/g, '');
        args.node.querySelectorAll('img').forEach(img => {
            img.classList.add('img-fluid');
        });
    },
};

function isImageResizeable(attachment) {
    const supportedMimeTypes = [
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/webp',
    ];

    return supportedMimeTypes.includes(attachment.data.mime_type);
}
</script>

<template>
    <div class="card">
        <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-chat-outline me-2 align-middle fs-4"></i>Messagerie</h4>
            <div class="btn-group" v-if="showDefaultMessages">
                <span class="btn btn-sm btn-soft-dark shadow-none" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="getDefaultMessages">
                    Message prédéfini
                </span>
                <button type="button" class="btn btn-sm btn-dark bg-gradient dropdown-toggle dropdown-toggle-split shadow-none" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="getDefaultMessages">
                </button>
                <div class="dropdown-menu dropdown-default-messages" data-simplebar>
                    <a class="dropdown-item" href="#" v-if="!defaultMessagesLoaded">Chargement....</a>
                    <a class="dropdown-item" href="#" @click.prevent="setDefaultMessage(defaultMessage.data.body)" v-for="defaultMessage in defaultMessages">{{ defaultMessage.data.title }}</a>
                </div>
            </div>
        </div>

        <div class="card-body p-0">
            <div id="users-chat">
                <div class="chat-conversation p-3" id="chat-conversation" data-simplebar :style="`max-height: ${maxHeight}px;`" ref="messagesContainer">
                    <ul class="list-unstyled chat-conversation-list chat-sm">
                        <li v-if="!lastPageIsLoaded">
                            <div class="chat-day-title text-muted">
                                <a href="#" class="title text-muted" @click="loadMoreMessages" v-if="!loading">Charger plus de messages</a>
                                <a href="#" class="title text-muted" @click="loadMoreMessages" v-else>Chargement...</a>
                            </div>
                        </li>
                        <li v-for="(messageGroup, index) in groupedMessages" :key="index" :class="getMessageClass(messageGroup[0])">
                            <div class="conversation-list">
                                <div v-if="messageGroup[0].data.sender_type === 'user' && !isCurrentUser(messageGroup[0])" class="chat-avatar hide-on-mobile">
                                    <img :src="'/format-image/'+messageGroup[0].data.sender.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" class="shadow">
                                </div>
                                <div v-if="messageGroup[0].data.sender_type === 'client' && !isCurrentUser(messageGroup[0])" class="chat-avatar hide-on-mobile">
                                    <img :src="brandConfig.logoSmall" class="shadow bg-dark" alt="logo">
                                </div>
                                <div class="user-chat-content">
                                    <div v-for="(message, indexMessage) in messageGroup" :key="indexMessage">
                                        <!--Message-->
                                        <div class="ctext-wrap mb-2" :title="'Envoyé le : '+ message.data.created_at_fr + '\nLu le : ' + message.data.read_at_fr" v-if="message.data.body !== null">
                                            <div class="ctext-wrap-content" :class="{ 'is-internal' : message.data.is_internal }">
                                                <div class="mb-0 ctext-content">
                                                    <div class="fw-semibold pb-1" v-if="message.data.sender_type === 'user' && !isCurrentUser(message) && indexMessage === 0">{{ message.data.sender.data.first_name }} {{ message.data.sender.data.last_name }}</div>
                                                    <div class="fw-semibold pb-1" v-if="message.data.sender_type === 'client' && !isCurrentUser(message) && indexMessage === 0">Client</div>
                                                    <div class="mb-0" v-html="message.data.body"></div>
                                                </div>
                                            </div>
                                            <div class="dropdown align-self-start message-box-drop" v-if="isCurrentUser(message)">
                                                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="ri-more-2-fill"></i>
                                                </a>
                                                <div class="dropdown-menu">
                                                    <!--<a class="dropdown-item" href="#" v-if="message.can.update">-->
                                                    <!--    <i class="ri-pencil-fill me-2 text-info align-bottom"></i>Modifier-->
                                                    <!--</a>-->
                                                    <!--Delete-->
                                                    <a class="dropdown-item delete-item" href="#"  @click.prevent="deleteComment(message.data.id)"  v-if="message.can.delete">
                                                        <i class="ri-delete-bin-5-line me-2 align-bottom text-danger"></i>Supprimer
                                                    </a>
                                                    <div class="dropdown-item delete-item" v-else>
                                                        <i class="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>Supprimer
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-for="(attachment, indexAttachment) in message.data.attachments" :key="indexAttachment">
                                            <!--Images-->
                                            <div class="ctext-wrap mb-2" :title="'Envoyé le : '+ message.data.created_at_fr + '\nLu le : ' + message.data.read_at_fr" v-if="isImageResizeable(attachment)">
                                                <div class="message-img mb-0">
                                                    <div class="message-img-list">
                                                        <div>
                                                            <a class="popup-img d-inline-block" href="#" @click="previewImage(attachment.data.path)">
                                                                <img :src="'/format-image/'+attachment.data.path+'?disk=public&w=88&h=59&fm=webp&fit=crop'" class="rounded border" :alt="attachment.data.name" width="88" height="59">
                                                            </a>
                                                        </div>
                                                        <div class="message-img-link">
                                                            <ul class="list-inline mb-0">
                                                                <li class="list-inline-item dropdown" v-if="isCurrentUser(message)">
                                                                    <a class="dropdown-toggle" :class="{ 'is-internal' : message.data.is_internal }" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i class="ri-more-fill"></i>
                                                                    </a>
                                                                    <div class="dropdown-menu">
                                                                        <!--<a class="dropdown-item" href="#" v-if="message.can.update">-->
                                                                        <!--    <i class="ri-pencil-fill me-2 text-info align-bottom"></i>Modifier-->
                                                                        <!--</a>-->
                                                                        <!--Delete-->
                                                                        <a class="dropdown-item delete-item" href="#" @click.prevent="deleteAttachment(attachment.data.id)" v-if="message.can.delete">
                                                                            <i class="ri-delete-bin-5-line me-2 align-bottom text-danger"></i>Supprimer
                                                                        </a>
                                                                        <div class="dropdown-item delete-item" v-else>
                                                                            <i class="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>Supprimer
                                                                        </div>

                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--Files-->
                                            <div class="ctext-wrap mb-2" :title="'Envoyé le : '+ message.data.created_at_fr + '\nLu le : ' + message.data.read_at_fr" v-else>
                                                <div class="ctext-wrap-content file px-2 py-1" :class="{ 'is-internal' : message.data.is_internal }">
                                                    <a class="mb-0 ctext-content" :href="attachment.data.path" target="_blank" >
                                                        <div class="row">
                                                            <div class="col-auto my-auto pe-2 align-middle ">
                                                                <i class="ri-video-line me-0 fs-24 file-message" :class="{ 'is-internal' : message.data.is_internal }" v-if="attachment.data.mime_type.includes('video')"></i>
                                                                <i class="ri-folder-music-line me-0 fs-24 file-message"  :class="{ 'is-internal' : message.data.is_internal }" v-else-if="attachment.data.mime_type.includes('audio')"></i>
                                                                <i class="ri-folder-zip-line me-0 fs-24 file-message" :class="{ 'is-internal' : message.data.is_internal }" v-else-if="attachment.data.mime_type.includes('zip')"></i>
                                                                <i class=" ri-file-pdf-line me-0 fs-24 file-message" :class="{ 'is-internal' : message.data.is_internal }" v-else-if="attachment.data.mime_type.includes('pdf')"></i>
                                                                <i class="ri-file-text-line me-0 fs-24 file-message" :class="{ 'is-internal' : message.data.is_internal }" v-else></i>
                                                            </div>
                                                            <div class="col ps-0 text-truncate my-auto file-message"  style="max-width: 250px">
                                                                <span :class="{ 'is-internal' : message.data.is_internal }">
                                                                    {{ attachment.data.name }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="dropdown align-self-start message-box-drop" v-if="isCurrentUser(message)">
                                                    <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="ri-more-2-fill"></i>
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <!--<a class="dropdown-item" href="#" v-if="message.can.update">-->
                                                        <!--    <i class="ri-pencil-fill me-2 text-info align-bottom"></i>Modifier-->
                                                        <!--</a>-->
                                                        <!--Delete-->
                                                        <a class="dropdown-item delete-item" href="#" @click.prevent="deleteAttachment(attachment.data.id)" v-if="message.can.delete">
                                                            <i class="ri-delete-bin-5-line me-2 align-bottom text-danger"></i>Supprimer
                                                        </a>
                                                        <div class="dropdown-item delete-item" v-else>
                                                            <i class="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>Supprimer
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="conversation-name">
                                        <small class="text-muted time">{{ messageGroup[0].data.created_at_fr }}</small>
                                        <span v-if="isLastMessageRead(messageGroup)" class="text-success check-message-icon">
                                            <i class="ri-check-double-line align-bottom fs-16"></i>
                                        </span>
                                        <span v-else class="text-muted check-message-icon">
                                            <i class="ri-check-line align-bottom fs-16"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="border-top border-top-dashed" v-if="canAnswer">
                <form v-on:submit.prevent="addMessage">
                    <div class="row g-2 mx-3 mt-2 mb-3">

                    <div class="col-12 col-md">
                        <div class="position-relative">
                            <div class="text-center my-2" v-if="tinyMceLoading">
                                <div class="spinner-grow text-dark" role="status">
                                </div>
                                <br>
                            </div>
                            <Editor
                                id="tinyEditor"
                                v-model="form.body"
                                :init="tinyMceConfig"
                            />
                            <span class="form-text text-muted mt-0">Enter = nouveau paragraphe / Maj + Enter = retour à la ligne.</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-auto">
                        <button type="submit" class="btn hide-on-mobile" :class="formValid ? 'btn-secondary' : 'disabled btn-dark'">
                            <i class="mdi mdi-send float-end" v-if="!sendingMessage"></i>
                            <i class="mdi mdi-spin mdi-loading float-end" v-else></i>
                        </button>
                        <div class="chat-input-links mt-1">
                            <button type="submit" class="btn hide-on-desktop me-2" :class="formValid ? 'btn-secondary' : 'disabled btn-dark'">
                                <i class="mdi mdi-send float-end" v-if="!sendingMessage"></i>
                                <i class="mdi mdi-spin mdi-loading float-end" v-else></i>
                            </button>
                            <div class="links-list-item">
                                <button type="button" class="btn btn-link text-decoration-none" @click.prevent="showFileInput = !showFileInput">
                                    <i class="bx bx bx-paperclip align-middle" :class="showFileInput ? 'text-info' : ''"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <form-input-file-pond
                        v-if="showFileInput"
                        name="form.attachments"
                        label="Fichiers"
                        :label-hidden="true"
                        :errors="errors"
                        :required="false"
                        :data="form.attachments"
                        @update:field="form.attachments = $event"
                        bs-class="col-md-12 mt-3"
                        :disabled="false"
                        :multiple="true"
                    />
                    <div class="form-check form-switch form-check-warning" style="margin-left: 5px;" v-if="showIsInternal">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="form.is_internal">
                        <label class="form-check-label text-muted" for="flexSwitchCheckChecked">Message interne</label>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
