<script setup>
import FullCalendar from '@fullcalendar/vue3'
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import axios from "axios";

const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const loading = ref(true);
const events = ref([]);
const showMyEvents = ref(true);
const showOtherEvents = ref(false);
const showGlobalEvents = ref(true);
const startDateSave =  ref(null);
const endDateSave = ref(null);
const eventToShow = ref(null);
const upcomingEvents = ref([]);
const calendarOptions = {
    timeZone: 'local',
    themeSystem: 'bootstrap',
    initialView: getInitialView(),
    plugins: [dayGridPlugin, listPlugin, timeGridPlugin],
    weekends: true,
    firstDay: 1,
    headerToolbar: {
        start: 'today prev,next',
        center: 'title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
    },
    eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    },
    events: events.value,
    expandRows: true,
    displayEventTime: true,
    locale: frLocale,
    eventColor: '#ffffff',
    scrollTime: '08:00:00',
    height: '100%',
    datesSet: async (dateInfo) => {
        getEvents(dateInfo.startStr, dateInfo.endStr);
    },
    eventClick: async (clickInfo) => {
        const event = events.value.find(event => parseInt(event.id) === parseInt(clickInfo.event.id));
        showModalEvent(event)
    },
    eventContent: function(arg) {
        let titleWithCenter = arg.event.extendedProps.center ? arg.event.title + ' - ' + arg.event.extendedProps.center : arg.event.title;

        if (arg.view.type === 'listMonth') {
            return { html: titleWithCenter };
        } else {
            return { html: arg.event.title };
        }
    }
};

onBeforeMount(() => {
    loadSettingsFromLocalStorage();
    getUpcomingEvents();
});

onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

watch([showMyEvents, showOtherEvents, showGlobalEvents], () => {
    localStorage.setItem('showMyEvents', showMyEvents.value ? 'true' : 'false');
    localStorage.setItem('showOtherEvents', showOtherEvents.value ? 'true' : 'false');
    localStorage.setItem('showGlobalEvents', showGlobalEvents.value ? 'true' : 'false');
});

function loadSettingsFromLocalStorage() {
    const showMyEventsStorage = localStorage.getItem('showMyEvents');
    const showOtherEventsStorage = localStorage.getItem('showOtherEvents');
    const showGlobalEventsStorage = localStorage.getItem('showGlobalEvents');

    if (showMyEventsStorage !== null) {
        showMyEvents.value = showMyEventsStorage === 'true';
    }
    if (showOtherEventsStorage !== null) {
        showOtherEvents.value = showOtherEventsStorage === 'true';
    }
    if (showGlobalEventsStorage !== null) {
        showGlobalEvents.value = showGlobalEventsStorage === 'true';
    }
}

function getEventColor(event) {
    if (event.center_id === null) {
        return 'bg-dark';
    }
    if (authUser.value.centers.find(center => center.id === event.center_id)) {
        return 'bg-secondary';
    }
    else {
        return 'bg-warning';
    }
}

function getEvents(startDate = null, endDate = null) {
    startDateSave.value = startDate;
    endDateSave.value = endDate;

    const start = new Date(startDate);
    start.setMonth(start.getMonth() - 2);
    const end = new Date(endDate);
    end.setMonth(end.getMonth() + 2);

    axios.get('/api/events', { params: {
            start_date: start,
            end_date: end,
            show_my_events: showMyEvents.value,
            show_other_events: showOtherEvents.value,
            show_global_events: showGlobalEvents.value
        } })
        .then(response => {
            events.value.splice(0, events.value.length);
            response.data.data.forEach(event => {
                events.value.push({
                    id: event.data.id,
                    title: event.data.title,
                    start: event.data.start_date,
                    end: event.data.all_day ? event.data.end_date_all_day : event.data.end_date,
                    className: getEventColor(event.data),
                    allDay: event.data.all_day,
                    location: event.data.location,
                    data : event.data,
                    can : event.can,
                    center: event.data.center_id === null ? null : event.data.center.data.name
                });
            });
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function getUpcomingEvents() {
    axios.get('/api/events', { params: {
            start_date: new Date(),
            take: 3,
            show_my_events: showMyEvents.value,
            show_other_events: showOtherEvents.value,
            show_global_events: showGlobalEvents.value
        } })
        .then(response => {
            upcomingEvents.value = response.data.data;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function getInitialView() {
    if (window.innerWidth >= 768 && window.innerWidth < 1200) {
        return 'timeGridWeek';
    } else if (window.innerWidth <= 768) {
        return 'listMonth';
    } else {
        return 'dayGridMonth';
    }
}

function showModalEvent(event) {
    eventToShow.value = event;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEvent'));
    modaleDelete.show()
}

function deleteEvent() {
    axios.delete('/api/events/' + eventToShow.value.data.id)
        .then(response => {
            const index = events.value.findIndex(event => event.id === eventToShow.value.id);
            events.value.splice(index, 1);
            getUpcomingEvents();
            const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEvent'));
            modaleDelete.hide();
            $toast.success('Événement supprimé avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function applyFilter(filterName) {
    if (filterName === 'showMyEvents') {
        showMyEvents.value = !showMyEvents.value;
    }
    if (filterName === 'showOtherEvents') {
        showOtherEvents.value = !showOtherEvents.value;
    }
    if (filterName === 'showGlobalEvents') {
        showGlobalEvents.value = !showGlobalEvents.value;
    }
    getEvents(startDateSave.value, endDateSave.value);
    getUpcomingEvents();
}

function isImage(data) {
    const imageMimeTypes = ['image/jpeg', 'image/png', 'image/webp'];
    const fileExtension = data.path.split('.').pop().toLowerCase();

    return imageMimeTypes.includes(data.mime_type) || ['jpg', 'jpeg', 'png', 'webp'].includes(fileExtension);
}
</script>

<template>
    <div class="row">
        <div class="col-xl-3">
            <div class="card card-h-100">
                <div class="card-body">
                    <router-link :to="{ name : 'events.create' }" class="btn btn-dark w-100 mb-3" v-if="authUser.can && authUser.can.create_events">
                        <i class="mdi mdi-plus"></i> Créer évènement
                    </router-link>
                    <div id="external-events">
                        <p class="text-muted">Cliquez pour afficher/cacher les évènements</p>
                        <div class="external-event bg-white text-secondary py-1 px-1">
                            <a href="#" class="text-secondary" @click.prevent="applyFilter('showMyEvents')">
                                <i class="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i><span :class="showMyEvents ? '' : 'text-decoration-line-through'">Évènements de votre centre</span>
                            </a>
                        </div>
                        <div class="external-event bg-white text-warning py-1 px-1" v-if="authUserStore.authUser.role === 'admin' || authUserStore.authUser.role === 'back_office'">
                            <a href="#" class="text-warning" @click.prevent="applyFilter('showOtherEvents')">
                                <i class="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i><span :class="showOtherEvents ? '' : 'text-decoration-line-through'">Évènements des autres centres</span>
                            </a>
                        </div>
                        <div class="external-event bg-white text-dark py-1 px-1">
                            <a href="#" class="text-dark" @click.prevent="applyFilter('showGlobalEvents')">
                                <i class="mdi mdi-checkbox-blank-circle font-size-11 me-2"></i><span :class="showGlobalEvents ? '' : 'text-decoration-line-through'">Évènements globaux</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h5 class="mb-1">Évènements à venir</h5>
                <p class="text-muted">Dates et heures selon le fuseau Europe/Paris.</p>
                <div class="pe-2 me-n1 mb-3">
                    <div>
                        <div class="card mb-3" v-for="(upcomingEvent, index) in upcomingEvents" :key="index">
                            <div class="card-body">
                                <a href="#" class="card-title fs-16 text-secondary h6" @click.prevent="showModalEvent(upcomingEvent)"> {{ upcomingEvent.data.title }}</a>
                                <div class="event-details mt-3">

                                    <div class="d-flex align-items-center mb-1">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-calendar-event-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="d-block fw-semibold mb-0">
                                                <span id="event-timepicker1-tag">
                                                    {{ upcomingEvent.data.start_end_date_fr }}
                                                </span>
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-1">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-time-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="d-block fw-semibold mb-0">
                                                <span id="event-timepicker1-tag">{{ upcomingEvent.data.all_day ? 'Journée entière' : upcomingEvent.data.start_end_hour_fr }}</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-1">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-map-pin-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="d-block fw-semibold mb-0"> <span >{{ upcomingEvent.data.location === null ? '--' : upcomingEvent.data.location }}</span></h6>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-1">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-building-4-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="d-block fw-semibold mb-0"> <span >{{ upcomingEvent.data.center_id === null ? 'Tous les centres' : upcomingEvent.data.center.data.name }}</span></h6>
                                        </div>
                                    </div>
                                </div>
                                <p class="text-muted text-truncate-two-lines mb-0"></p>
                            </div>
                        </div>
                        <!-- No events-->
                        <div class="mt-4 text-center" v-if="upcomingEvents.length === 0">
                            <div class="text-center">
                                <i class="mdi mdi-calendar-remove fs-36 text-muted"></i><p class="text-muted">Aucun évènement</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--Model event-->
            <div class="modal fade zoomIn" id="modalEvent" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" v-if="eventToShow !== null">
                        <div class="modal-header">
                            <h5 class="modal-title text-secondary">{{ eventToShow.data.title }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-4">
                            <form class="needs-validation view-event" name="event-form" id="form-event" novalidate="">
                                <div class="event-details">
                                    <div class="d-flex mb-2">
                                        <div class="flex-grow-1 d-flex align-items-center">
                                            <div class="flex-shrink-0 me-3">
                                                <i class="ri-calendar-event-line text-muted fs-16"></i>
                                            </div>
                                            <div class="flex-grow-1">
                                                <h6 class="d-block fw-semibold mb-0">{{ eventToShow.data.start_end_date_fr }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-time-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="d-block fw-semibold mb-0">
                                                {{ eventToShow.data.all_day ? 'Journée entière' : eventToShow.data.start_end_hour_fr }}
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-map-pin-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="d-block fw-semibold mb-0">
                                                {{ eventToShow.data.location === null ? '--' : eventToShow.data.location }}
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-building-4-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="d-block fw-semibold mb-0">
                                                {{ eventToShow.data.center_id === null ? 'Tous les centres' : eventToShow.data.center.data.name }}
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="d-flex mb-3">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-discuss-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <p class="d-block mb-0 align-middle" v-html="eventToShow.data.description === null ? '--' : eventToShow.data.description.replace(/\n/g, '<br />')">
                                            </p>
                                        </div>
                                    </div>
                                    <div class="vstack gap-2 mb-3">

                                        <div class="border rounded border-dashed p-2" v-for="(attachment, index) in eventToShow.data.attachments" :key="index">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 me-3">
                                                    <div class="avatar-sm" v-if="isImage(attachment.data)">
                                                        <img :src="'/format-image/'+attachment.data.path+'?w=48&h=48&fm=webp&fit=crop'" class="rounded">
                                                    </div>
                                                    <div class="avatar-sm" v-else>
                                                        <div class="avatar-title bg-light text-secondary rounded fs-24 shadow">
                                                            <!--Icon depending mime type-->
                                                            <i class="ri-image-2-line" v-if="attachment.data.mime_type.includes('image')"></i>
                                                            <i class="ri-video-line" v-else-if="attachment.data.mime_type.includes('video')"></i>
                                                            <i class="ri-folder-music-line" v-else-if="attachment.data.mime_type.includes('audio')"></i>
                                                            <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('zip')"></i>
                                                            <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('pdf')"></i>
                                                            <i class="ri-file-text-line" v-else></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 overflow-hidden">
                                                    <h5 class="fs-13 mb-1"><a :href="attachment.data.path" target="_blank" class="text-body text-truncate d-block">{{ attachment.data.name }}</a></h5>
                                                    <div>{{ attachment.data.size }}</div>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <div class="d-flex gap-1">
                                                        <a :href="attachment.data.path" target="_blank" class="btn btn-icon text-muted btn-sm fs-18 shadow-none"><i class="ri-download-2-line"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="text-end mb-0">
                                    <a href="#" class="btn btn-sm btn-soft-danger me-2" id="edit-event-btn" role="button" v-if="eventToShow.can.delete" @click.prevent="deleteEvent()">
                                        <i class="ri-close-line align-bottom"></i> Supprimer
                                    </a>
                                    <router-link :to="{ name: 'events.edit', params : { id : eventToShow.data.id } }" class="btn btn-sm btn-soft-secondary" id="edit-event-btn" role="button"  v-if="eventToShow.can.update">
                                        Modifier
                                    </router-link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-9">
            <div class="card">
                <div class="card-body body-calendar">
                    <FullCalendar :options='calendarOptions' ref="calendarRef" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.body-calendar{
    height: 83vh !important;
}
</style>
