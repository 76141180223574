<script setup>
import FullCalendar from '@fullcalendar/vue3'
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import axios from "axios";
import {useRouter} from "vue-router";
import FormSelectCenters from "../../Components/FormSelectCenters.vue";

const { httpErrorsHandler } = useHttpErrorsHandler();
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
const router = useRouter();

const loading = ref(true);
const errors = ref(null);
const events = ref([]);
const startDateSave =  ref(null);
const endDateSave = ref(null);
const eventToShow = ref(null);
const upcomingEvents = ref([]);
const calendarOptions = {
    timeZone: 'local',
    themeSystem: 'bootstrap',
    initialView: 'timeGridWeek',
    plugins: [dayGridPlugin, listPlugin, timeGridPlugin],
    weekends: true,
    firstDay: 1,
    headerToolbar: {
        start: 'today prev,next',
        center: 'title',
        end: 'timeGridWeek,timeGridThreeDay,timeGridDay,listMonth'
    },
    eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    },
    events: events.value,
    expandRows: true,
    displayEventTime: true,
    nowIndicator: true,
    locale: frLocale,
    eventColor: '#ffffff',
    scrollTime: new Date().toLocaleTimeString('en-GB', { hour12: false }),
    eventOverlap: false,
    slotEventOverlap: false,
    allDaySlot : false,
    height: '100%',
    slotMinTime: '07:00:00',
    slotMaxTime: '20:00:00',
    datesSet: async (dateInfo) => {
        getEvents(dateInfo.startStr, dateInfo.endStr);
    },
    eventClick: async (clickInfo) => {
        const event = events.value.find(event => parseInt(event.id) === parseInt(clickInfo.event.id));
        await router.push({name: 'client-requests.show', params: {id: event.data.client_request_id}});
    },
    eventContent: function(arg) {
        let titleWithCenter = arg.event.extendedProps.center ? arg.event.title + ' - ' + arg.event.extendedProps.center : arg.event.title;

        if (arg.view.type === 'listMonth') {
            return { html: titleWithCenter };
        } else {
            return { html: arg.event.title };
        }
    },
    views: {
        timeGridThreeDay: {
            type: 'timeGrid',
            duration: { days: 3 },
            buttonText: '3 Jours'
        },
    }
};

onBeforeMount(() => {
    loadSettingsFromLocalStorage();
    getUpcomingEvents();
});

onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

const selectedCenters = ref([]);
const preSelectedCenters = ref([]);
function loadSettingsFromLocalStorage() {
    if (localStorage.getItem('clientRequestAgendaSelectedCenters') !== null) {
        selectedCenters.value = JSON.parse(localStorage.getItem('clientRequestAgendaSelectedCenters'));
        preSelectedCenters.value = selectedCenters.value.map(center => {
            return { data: { id: center.id, name: center.name }};
        });
    } else {
        selectedCenters.value = authUser.value.centers;
        preSelectedCenters.value = selectedCenters.value.map(center => {
            return { data: { id: center.id, name: center.name }};
        });
    }
}

watch(selectedCenters, (newValue) => {
    localStorage.setItem('clientRequestAgendaSelectedCenters', JSON.stringify(newValue));
});

function loadEventsForSelectedCenters() {
    getEvents(startDateSave.value, endDateSave.value);
    getUpcomingEvents();
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalSelectCenters'));
    modal.hide();
}

function getEvents(startDate = null, endDate = null) {
    startDateSave.value = startDate;
    endDateSave.value = endDate;

    const start = new Date(startDate);
    start.setMonth(start.getMonth() - 2);
    const end = new Date(endDate);
    end.setMonth(end.getMonth() + 2);

    axios.get('/api/events', { params: {
            start_date: start,
            end_date: end,
            show_appointments_events: true,
            centers_id: selectedCenters.value.map(center => center.id)
        } })
        .then(response => {
            events.value.splice(0, events.value.length);
            response.data.data.forEach(event => {
                events.value.push({
                    id: event.data.id,
                    title: event.data.title,
                    start: event.data.start_date,
                    end: event.data.all_day ? event.data.end_date_all_day : event.data.end_date,
                    backgroundColor: event.data.color,
                    allDay: event.data.all_day,
                    location: event.data.location,
                    data : event.data,
                    can : event.can,
                    center: event.data.center_id === null ? null : event.data.center.data.name
                });
            });
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function getUpcomingEvents() {
    axios.get('/api/events', { params: {
            start_date: new Date(),
            take: 4,
            show_appointments_events: true,
            upcoming_appointments_events: true,
            centers_id: selectedCenters.value.map(center => center.id)
        } })
        .then(response => {
            upcomingEvents.value = response.data.data;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function showModalCenters(event) {
    eventToShow.value = event;
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalSelectCenters'));
    modal.show()
}
</script>

<template>
    <div class="row">
        <div class="col-xl-3">
            <a
                href="#"
                class="btn btn-dark bg-gradient w-100 mb-4 align-middle "
                @click.prevent="showModalCenters"
            >
                <i class="mdi mdi-filter-menu fs-14 align-middle me-2"></i> Choix centres
            </a>
            <div>
                <h5 class="mb-1">Prochains RDV</h5>
                <p class="text-muted">Dates et heures selon le fuseau Europe/Paris.</p>
                <div class="pe-2 me-n1 mb-3">
                    <div>
                        <div class="card mb-3" v-for="(upcomingEvent, index) in upcomingEvents" :key="index">
                            <div class="card-body">
                                <a href="#" class="card-title fs-16 h6" :style="{color: upcomingEvent.data.color ?? '2d65cd'}" @click.prevent="router.push({name: 'client-requests.show', params: {id: upcomingEvent.data.client_request_id}})"> {{ upcomingEvent.data.title }}</a>
                                <div class="event-details mt-3">

                                    <div class="d-flex align-items-center mb-1">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-calendar-event-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="d-block fw-semibold mb-0">
                                                <span id="event-timepicker1-tag">
                                                    {{ upcomingEvent.data.start_end_date_fr }}
                                                </span>
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-1">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-time-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="d-block fw-semibold mb-0">
                                                <span id="event-timepicker1-tag">{{ upcomingEvent.data.all_day ? 'Journée entière' : upcomingEvent.data.start_end_hour_fr }}</span>
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-1">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-map-pin-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="d-block fw-semibold mb-0"> <span >{{ upcomingEvent.data.location === null ? '--' : upcomingEvent.data.location }}</span></h6>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-1">
                                        <div class="flex-shrink-0 me-3">
                                            <i class="ri-price-tag-3-line text-muted fs-16"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="d-block fw-semibold mb-0"> <span >{{ upcomingEvent.data.subtype === null ? '--' : upcomingEvent.data.subtype }}</span></h6>
                                        </div>
                                    </div>
                                </div>
                                <p class="text-muted text-truncate-two-lines mb-0"></p>
                            </div>
                        </div>
                        <!-- No events-->
                        <div class="mt-4 text-center" v-if="upcomingEvents.length === 0">
                            <div class="text-center">
                                <i class="mdi mdi-calendar-remove fs-36 text-muted"></i><p class="text-muted">Aucun RDV</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Signature -->
            <div id="modalSelectCenters" class="modal fade" tabindex="-1" aria-labelledby="modalSelectCenters" aria-hidden="true" style="display: none;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalSelectCenters">Selection de centres</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div class="modal-body">
                            <form-select-centers
                                :errors="errors"
                                label="Centres"
                                name="centers"
                                :required="true"
                                :data="selectedCenters"
                                @update:field="selectedCenters = $event"
                                bs-class="col-md-12"
                                :disabled="false"
                                :taggable="false"
                                :label-hidden="false"
                                :min="1"
                                :centers-pre-selected="preSelectedCenters"
                                :allow-empty="false"
                                placeholder="Sélectionnez un ou plusieurs centres"
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                            <button type="button" class="btn btn-secondary fw-medium text-decoration-none shadow-none" @click="loadEventsForSelectedCenters">Valider</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-9">
            <div class="card">
                <div class="card-body body-calendar">
                    <FullCalendar :options='calendarOptions' ref="calendarRef" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.body-calendar{
    height: 83vh !important;
}
</style>
